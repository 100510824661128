<template>
  <div class="empty-state">
    <p>
      No hay {{ label || 'elementos' }} que mostrar<template v-if="haveAction">.</template>
      <template v-else> con la búsqueda ingresada.</template>
      <br />
      <template v-if="haveAction">
        Cambia la búsqueda ingresada o
        <u @click="$emit('click')">agrega uno nuevo.</u>
      </template>
    </p>
  </div>
</template>

<script>
import {
  faGreaterThan,
  faUser,
  faGear,
  faBalanceScale,
  faFolderClosed,
} from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'EmptyState',

  components: {},

  data: () => ({
    icons: {
      '>': faGreaterThan,
      user: faUser,
      settings: faGear,
      balance: faBalanceScale,
      folder: faFolderClosed,
    },
  }),

  props: {
    label: {
      type: String,
    },
    haveAction: {
      type: Boolean,
      default: true,
    },
  },

  computed: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.empty-state {
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    font-style: italic;
    text-align: center;
    line-height: 1.5rem;
    u {
      cursor: pointer;
      &:hover {
        background-color: var(--gray-color-100);
      }
    }
  }
}
</style>
