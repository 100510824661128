<template>
  <div v-if="isEditing" v-click-outside="onCancel" class="editor">
    <editor-content class="editor__editor" :editor="editor" />
    <div class="editor__menu" v-if="editor">
      <button
        title="Negrita"
        class="editor__btn"
        :class="{ 'editor__btn--active': editor.isActive('bold') }"
        @click="editor.chain().toggleBold().focus().run()"
      >
        <font-awesome-icon :icon="icons.bold" />
      </button>

      <button
        title="Cursiva"
        class="editor__btn"
        :class="{ 'editor__btn--active': editor.isActive('italic') }"
        @click="editor.chain().toggleItalic().focus().run()"
      >
        <font-awesome-icon :icon="icons.italic" />
      </button>

      <button
        title="Subrayado"
        class="editor__btn"
        :class="{ 'editor__btn--active': editor.isActive('underline') }"
        @click="editor.chain().toggleUnderline().focus().run()"
      >
        <font-awesome-icon :icon="icons.underline" />
      </button>

      <div class="divider"></div>

      <button
        title="Título 1"
        class="editor__btn"
        :class="{
          'editor__btn--active': editor.isActive('heading', { level: 2 }),
        }"
        @click="editor.chain().toggleHeading({ level: 2 }).focus().run()"
      >
        <font-awesome-icon :icon="icons.header" />
      </button>

      <button
        title="Lista"
        class="editor__btn"
        :class="{
          'editor__btn--active': editor.isActive('bulletList'),
        }"
        @click="editor.chain().toggleBulletList().focus().run()"
      >
        <font-awesome-icon :icon="icons.ul" />
      </button>

      <button
        title="Lista Ordenada"
        class="editor__btn"
        :class="{
          'editor__btn--active': editor.isActive('orderedList'),
        }"
        @click="editor.chain().toggleOrderedList().focus().run()"
      >
        <font-awesome-icon :icon="icons.ol" />
      </button>

      <div class="divider"></div>

      <button
        title="Izquierda"
        class="editor__btn"
        :class="{
          'editor__btn--active': editor.isActive({ textAlign: 'left' }),
        }"
        @click="editor.chain().setTextAlign('left').focus().run()"
      >
        <font-awesome-icon :icon="icons.left" />
      </button>

      <button
        title="Centrar"
        class="editor__btn"
        :class="{
          'editor__btn--active': editor.isActive({ textAlign: 'center' }),
        }"
        @click="editor.chain().setTextAlign('center').focus().run()"
      >
        <font-awesome-icon :icon="icons.center" />
      </button>

      <button
        title="Derecha"
        class="editor__btn"
        :class="{
          'editor__btn--active': editor.isActive({ textAlign: 'right' }),
        }"
        @click="editor.chain().setTextAlign('right').focus().run()"
      >
        <font-awesome-icon :icon="icons.right" />
      </button>

      <button
        title="Justificar"
        class="editor__btn"
        :class="{
          'editor__btn--active': editor.isActive({ textAlign: 'justify' }),
        }"
        @click="editor.chain().setTextAlign('justify').focus().run()"
      >
        <font-awesome-icon :icon="icons.justify" />
      </button>

      <div class="divider"></div>

      <input
        type="color"
        @input="editor.chain().focus().setColor($event.target.value).run()"
        :value="editor.getAttributes('textStyle').color"
      />

      <div class="divider"></div>

      <button title="Deshacer" class="editor__btn" @click="editor.chain().undo().focus().run()">
        <font-awesome-icon :icon="icons.undo" />
      </button>

      <button title="Rehacer" class="editor__btn" @click="editor.chain().redo().focus().run()">
        <font-awesome-icon :icon="icons.redo" />
      </button>

      <button v-if="textChanged" title="Guardar" class="editor__btn" @click="onSave">
        <font-awesome-icon :icon="icons.save" />
      </button>
    </div>
  </div>

  <div v-else v-html="value" @click="onEdit" class="tiptap__preview"></div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import { TextAlign } from '@tiptap/extension-text-align';
import { Underline } from '@tiptap/extension-underline';
import { Heading } from '@tiptap/extension-heading';
import { Paragraph } from '@tiptap/extension-paragraph';
import { BulletList } from '@tiptap/extension-bullet-list';
import { OrderedList } from '@tiptap/extension-ordered-list';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';

import {
  faAlignCenter,
  faAlignJustify,
  faAlignLeft,
  faAlignRight,
  faBold,
  faItalic,
  faList,
  faListOl,
  faRedo,
  faUnderline,
  faUndo,
  faHeading,
  faSave,
} from '@fortawesome/free-solid-svg-icons';

import vClickOutside from 'v-click-outside';

export default {
  components: { EditorContent },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  data: () => ({
    editor: null,
    originalText: '',
    isEditing: false,
    textChanged: false,
    icons: {
      bold: faBold,
      italic: faItalic,
      underline: faUnderline,
      header: faHeading,
      ul: faList,
      ol: faListOl,
      left: faAlignLeft,
      center: faAlignCenter,
      right: faAlignRight,
      justify: faAlignJustify,
      undo: faUndo,
      redo: faRedo,
      save: faSave,
    },
  }),

  watch: {
    value(value) {
      if (value !== this.originalText) this.textChanged = true;
      else this.textChanged = false;

      const isSame = this.editor.getHTML() === value;

      if (isSame) return;

      this.editor.commands.setContent(this.value, false);
    },
  },

  methods: {
    onEdit() {
      this.isEditing = true;
      this.originalText = this.value;
    },

    onCancel() {
      this.isEditing = false;
      this.textChanged = false;
      this.$emit('input', this.originalText);
      this.originalText = '';
    },

    onSave() {
      this.$emit('save');
      this.isEditing = false;
      this.textChanged = false;
      this.originalText = '';
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Heading.configure({
          HTMLAttributes: {
            class: 'releases-heading',
          },
        }),
        Paragraph.configure({
          HTMLAttributes: {
            class: 'releases-paragraph',
          },
        }),
        OrderedList.configure({
          HTMLAttributes: {
            class: 'releases-list',
          },
        }),
        BulletList.configure({
          HTMLAttributes: {
            class: 'releases-list',
          },
        }),
        TextAlign,
        Underline,
        TextStyle,
        Color.configure({
          types: ['textStyle'],
        }),
      ],
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML());
      },
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
.editor {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.releases-paragraph {
  line-height: 1.5rem;
}

.releases-heading {
  line-height: 2.175rem;
}

.editor__editor {
  flex-grow: 1;
}

.ProseMirror {
  max-width: 100%;
  height: 100%;
  overflow: auto;
}

.ProseMirror:focus {
  outline: none;
  border-color: var(--gray-color-300);
}

.editor__menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem 0.375rem;
  border-width: 1px 1px 0 1px;
  margin-top: 1rem;
  border-top: solid 2px var(--gray-color-200);
}

.editor__btn {
  padding: 0.375rem;
  border-radius: 2px;
  color: var(--font-color-300);
}

.editor__btn + .editor__btn {
  margin-left: 0.375rem;
}

.editor__btn--active,
.editor__btn:hover {
  color: var(--font-color-700);
  background-color: var(--gray-color-200);
}

.editor .divider {
  width: 2px;
  height: 1.125rem;
  margin: 0 0.875rem;
  background-color: #e5e5e5;
}

.tiptap__preview {
  width: calc(100% - 0.2rem);
  height: 100%;
  border-radius: 0.375rem;
  cursor: text;
}

.tiptap__preview:hover {
}

input[type='color'] {
  border: none;
  border-radius: 4px;
}
input[type='color']::-webkit-color-swatch {
  border: none;
  border-radius: 4px;
}
</style>
